import { NavBar } from "@components";
import { useWallet } from "@hooks";
import logo from "@images/logo.png";
import { Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { Fragment, ReactElement } from "react";
import Helmet from "react-helmet";

import { footer, footerLinks, layout } from "./style.module.scss";

interface Props {
    children: Node;
}

const Layout = ({ children }: Props): ReactElement => {
    const {
        currentBuildDate: { currentDate },
        contentfulNavigation: {
            navLinks,
            externalLinks,
            twitterLink,
            discordLink,
            openseaLink,
            bottomLinks,
        },
    } = useStaticQuery(
        graphql`
            query {
                currentBuildDate {
                    currentDate
                }
                contentfulNavigation(slug: { eq: "navigation" }) {
                    navLinks {
                        id
                        label
                        changePage
                        link {
                            path
                        }
                    }
                    externalLinks {
                        id
                        label
                        link
                    }
                    twitterLink
                    discordLink
                    openseaLink
                    bottomLinks {
                        id
                        label
                        changePage
                        link {
                            path
                        }
                    }
                }
            }
        `,
    );

    // hooks
    const { connect, signer } = useWallet();

    return (
        <Fragment>
            <Helmet>
                <title>Beefy Blokes</title>
                {currentDate && (
                    <meta name="build:timestamp" content={currentDate} />
                )}
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Beefy Blokes" />
                <meta property="og:url" content="https://www.beefyblokes.com" />
                <meta property="og:title" content="Beefy Blokes" />
                <meta
                    property="og:description"
                    content="Beefy Blokes is a collection of 4110 NFT’s inhabiting the Ethereum Blockchain. They are attempting to solve one of the biggest beef in the space, and that is the lack of mentoring and education for the daily bloke."
                />
                <meta property="og:image" content={logo} />
                <meta name="twitter:image" content={logo} />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <NavBar
                signer={signer}
                handleConnect={connect}
                navLinks={navLinks}
                externalLinks={externalLinks}
                twitterLink={twitterLink}
                discordLink={discordLink}
                openseaLink={openseaLink}
            />
            <div id="home" className={layout}>
                {children}
            </div>
            <footer className={footer}>
                <Typography>
                    All rights reserved &copy; {new Date().getFullYear()}
                </Typography>
                {!!bottomLinks.length && (
                    <div className={footerLinks}>
                        {bottomLinks.map(
                            ({ id, label, changePage, link: { path } }) => (
                                <AnchorLink
                                    key={id}
                                    to={changePage ? `/${path}` : `/#${path}`}
                                    title={label}
                                    stripHash
                                >
                                    <Typography
                                        variant="bebas"
                                        component="span"
                                    >
                                        {label}
                                    </Typography>
                                </AnchorLink>
                            ),
                        )}
                    </div>
                )}
            </footer>
        </Fragment>
    );
};

export default Layout;
