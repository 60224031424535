import { Accordion } from "@components";
import { Typography } from "@mui/material";
import React from "react";

interface Faq {
    id: string;
    question: string;
    answer: unknown;
}

interface Props {
    id: string;
    title: string;
    faqs: Faq[];
}

const Faqs = ({ id, title, faqs }: Props): Node => (
    <div id={id}>
        <Typography variant="title" component="h2">
            {title}
        </Typography>
        {faqs.map(({ id, question, answer: { raw: answer } }) => (
            <Accordion key={id} question={question} answer={answer} />
        ))}
    </div>
);

export default Faqs;
