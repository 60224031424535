import { Faqs } from "@components";
import { graphql } from "gatsby";
import React from "react";

const Faq = ({ data: { slug, title, faqs } }: unknown): Node => (
    <Faqs id={slug.path} title={title} faqs={faqs} />
);

export default Faq;

export const query = graphql`
    fragment FaqsData on ContentfulSectionFaq {
        id
        slug {
            path
        }
        title
        faqs {
            id
            question
            answer {
                raw
            }
        }
    }
`;
