import { CFImage } from "@types";
import React from "react";

import { bannerImage } from "./style.module.scss";

interface Props extends CFImage {
    id: string;
}

const BannerImage = ({ id, src, alt, width, height }: Props): Node => (
    <img
        id={id}
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={bannerImage}
    />
);

export default BannerImage;
