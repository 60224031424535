import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Typography } from "@mui/material";
import { graphql } from "gatsby";
import React from "react";

import { aboutContainer } from "./style.module.scss";

const About = ({ data: { slug, title, content } }: unknown): Node => (
    <div id={slug.path} className={aboutContainer}>
        <Typography variant="title" component="h2">
            {title}
        </Typography>
        <Typography component="div">
            {documentToReactComponents(JSON.parse(content.raw))}
        </Typography>
    </div>
);

export default About;

export const query = graphql`
    fragment AboutData on ContentfulSectionAbout {
        id
        slug {
            path
        }
        title
        content {
            raw
        }
    }
`;
