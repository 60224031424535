import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useWindow } from "@hooks";
import { Button, Typography } from "@mui/material";
import { graphql } from "gatsby";
import React, { Fragment, useState } from "react";

import { Bottomsheet } from "../../components";
import {
    roadmapContent,
    step,
    stepContent,
    steps,
    visual,
} from "./style.module.scss";

const Roadmap = ({
    data: { slug, title, image, roadmapItems },
}: unknown): Node => {
    // state
    const [openRoadmap, setOpenRoadmap] = useState<boolean>(false);

    // hooks
    const { width } = useWindow();

    const RoadmapDetails = (
        <section className={steps}>
            {roadmapItems.map(({ stepTitle, content }) => (
                <article key={stepTitle} className={step}>
                    <Typography variant="bebas" component="h3">
                        {stepTitle}
                    </Typography>
                    <Typography component="div" className={stepContent}>
                        {documentToReactComponents(JSON.parse(content.raw))}
                    </Typography>
                </article>
            ))}
        </section>
    );

    return (
        <div id={slug.path}>
            <Typography variant="title" component="h2">
                {title}
            </Typography>
            <div className={roadmapContent}>
                <img
                    src={image.file.url}
                    alt={image.file.alt}
                    width={image.file.details.image.width}
                    height={image.file.details.image.height}
                    className={visual}
                />
                {width < 768 ? (
                    <Fragment>
                        <Button onClick={() => setOpenRoadmap(true)}>
                            Open roadmap details
                        </Button>
                        <Bottomsheet
                            open={openRoadmap}
                            onClose={() => setOpenRoadmap(false)}
                        >
                            {RoadmapDetails}
                        </Bottomsheet>
                    </Fragment>
                ) : (
                    RoadmapDetails
                )}
            </div>
        </div>
    );
};

export default Roadmap;

export const query = graphql`
    fragment RoadmapData on ContentfulSectionRoadmap {
        id
        slug {
            path
        }
        title
        image {
            file {
                url
                details {
                    image {
                        width
                        height
                    }
                }
            }
        }
        roadmapItems {
            stepTitle
            content {
                raw
            }
        }
    }
`;
