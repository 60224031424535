import classnames from "classnames";
import React from "react";

import {
    backdrop,
    bottomsheet,
    bottomsheetContainer,
    fullHeight,
    isOpen,
} from "./style.module.scss";

interface Props {
    open: boolean;
    fullheight?: boolean;
    onClose: () => void;
    children: Node;
    className?: string;
}

const Bottomsheet = ({
    open,
    fullheight,
    onClose,
    children,
    className,
}: Props): Node => (
    <div
        className={classnames(bottomsheetContainer, className, open && isOpen)}
    >
        <div className={backdrop} onClick={() => onClose()} />
        <div className={classnames(bottomsheet, fullheight && fullHeight)}>
            {children}
        </div>
    </div>
);

export default Bottomsheet;
