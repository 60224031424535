import { useWindow } from "@hooks";
import { useSetAlert } from "@hooks/recoil";
import metamask from "@images/icons/metamask.png";
import walletConnect from "@images/icons/wallet-connect.png";
import { Box, Button, Modal, Typography } from "@mui/material";
import React, { Fragment, ReactElement, useState } from "react";

import { connectBtn, modalBtns, walletModal } from "./style.module.scss";

interface Props {
    signer: unknown;
    handleConnect: (type?: string, onClose?: () => void) => void;
}

const WalletConnector = ({ signer, handleConnect }: Props): ReactElement => {
    const [open, setOpen] = useState<boolean>(false);

    const setAlert = useSetAlert();
    const { width } = useWindow();

    if (typeof width === "undefined") return null;

    return (
        <Fragment>
            <Button
                onClick={() => (signer ? handleConnect() : setOpen(true))}
                className={connectBtn}
            >
                {signer ? "Disconnect" : "Connect"}
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box className={walletModal}>
                    <Typography variant="bebas" component="h3">
                        Choose a wallet provider
                    </Typography>
                    <div className={modalBtns}>
                        <Button
                            variant="image"
                            onClick={() =>
                                handleConnect("walletConnect", () =>
                                    setOpen(false),
                                )
                            }
                        >
                            <img src={walletConnect} />
                            <span>WalletConnect</span>
                        </Button>
                        <Button
                            variant="image"
                            onClick={() => {
                                // disable MM wallet connect when MetaMask is unavailable
                                if (typeof window.ethereum === "undefined") {
                                    return setAlert({
                                        severity: "info",
                                        message:
                                            "Please use Metamask browser or WalletConnect",
                                    });
                                }

                                handleConnect("metamask", () => setOpen(false));
                            }}
                        >
                            <img src={metamask} />
                            <span>MetaMask</span>
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default WalletConnector;
