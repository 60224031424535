import { utils } from "ethers";

import getGaslimit from "./get-gaslimit";

export default (value?: unknown, estimateGas?: string | number) => {
    let options = {};

    if (value && typeof value !== "undefined") {
        options = {
            ...options,
            value,
        };
    }

    if (estimateGas) {
        options = {
            ...options,
            gasLimit: utils.parseUnits(String(getGaslimit(estimateGas)), 0),
        };
    }

    return options;
};
