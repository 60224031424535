/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import "./src/styles/index.scss";

import { Layout, Snackbar } from "@containers";
import { ThemeProvider } from "@mui/material/styles";
import { Web3ReactProvider } from "@web3-react/core";
import { providers } from "ethers";
import React from "react";
import { RecoilRoot } from "recoil";

import theme from "./src/styles/theme";

export const wrapRootElement = ({ element }) => (
    <RecoilRoot>
        <Web3ReactProvider
            getLibrary={provider => new providers.Web3Provider(provider)}
        >
            <ThemeProvider theme={theme}>
                <Layout>
                    {element}
                    <Snackbar />
                </Layout>
            </ThemeProvider>
        </Web3ReactProvider>
    </RecoilRoot>
);
