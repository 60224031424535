import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useContract, useWallet } from "@hooks";
import { Button, ButtonGroup, LinearProgress, Typography } from "@mui/material";
import { contractAbi } from "@utils";
import { utils } from "ethers";
import { graphql, useStaticQuery } from "gatsby";
import { MerkleTree } from "merkletreejs";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import { mintCard, mintInfo, mintSection } from "./style.module.scss";

interface MintDetails {
    totalSupply: string;
    tokenPrice: string | undefined;
    maxAmount: string;
    tokenAmount?: string;
    saleState: 0 | 1 | 2 | 3;
}

/* destructure ethers utils */
const { formatUnits, parseEther, keccak256 } = utils;

const Mint = ({
    data: { slug, title, description, mintGif },
}: unknown): ReactElement => {
    /* merkletree logic */
    const {
        allWhitelistCsv: { nodes },
    } = useStaticQuery(
        graphql`
            query {
                allWhitelistCsv {
                    nodes {
                        wallet
                    }
                }
            }
        `,
    );

    const whitelist = nodes.map(({ wallet }) => wallet);
    const whitelistTree = useMemo(
        () =>
            new MerkleTree(whitelist.map(keccak256), keccak256, {
                sortPairs: true,
            }),
        [whitelist],
    );

    useEffect(() => {
        !!whitelistTree &&
            console.log("Whitelist Root", whitelistTree.getHexRoot());
    }, [whitelistTree]);

    /* state */
    const [mintDetails, setMintDetails] = useState<MintDetails>(undefined);
    const [mintDisabled, setMintDisabled] = useState<boolean>(true);
    const [mintAmount, setMintAmount] = useState<number>(1);

    /* hooks */
    const { account } = useWallet();
    const [inProgress, setTxInProgress] = useState<boolean>(false);
    const { contract, execContract } = useContract(
        process.env.GATSBY_CONTRACT_ADDRESS,
        contractAbi,
    );

    /* helpers */
    const getMintDetails = useCallback(async () => {
        const totalSupply = formatUnits(await contract.totalSupply(), 0);
        const mintPrice = formatUnits(await contract.tokenPrice());
        const presalePrice = formatUnits(await contract.preSaleTokenPrice());
        const saleState = parseInt(
            formatUnits(await contract.saleState(), 0),
        ) as 0 | 1 | 2 | 3;

        setMintDetails({
            totalSupply,
            tokenPrice: saleState < 2 ? presalePrice : mintPrice,
            maxAmount: saleState < 2 ? "5" : "21",
            saleState,
        });
    }, [contract]);

    const getTokensInWallet = useCallback(
        async account => {
            const tokenAmount = formatUnits(
                await contract.balanceOf(account),
                0,
            );

            setMintDetails(prevState => ({
                ...prevState,
                tokenAmount,
            }));
        },
        [contract],
    );

    const getMintbtnLabel = () => {
        if (!mintDetails) return "Loading...";

        const { saleState, maxAmount, tokenAmount } = mintDetails;
        if (saleState === 0) return "Mint not yet started";
        if (saleState > 0 && !account) return "Please connect a wallet";

        if (
            saleState === 1 &&
            getMaxMintAmount(saleState, maxAmount, tokenAmount) <= 0
        ) {
            return "Maximum claim reached";
        }

        if (saleState === 1 && !whitelist.includes(account)) {
            return "You're not whitelisted";
        }

        !!mintDisabled && setMintDisabled(false);
        return "Mint your bloke(s)";
    };

    const getMaxMintAmount = (
        saleState: number,
        maxAmount: string,
        tokenAmount = "0",
    ): number => {
        if (saleState > 1) {
            return parseInt(maxAmount) - 1;
        }

        return parseInt(maxAmount) - parseInt(tokenAmount) - 1;
    };

    const getMethod = (saleState: number) => {
        if (saleState === 1) return "claim";
        if (saleState === 2) return "mintPreSale";
        if (saleState === 3) return "mint";
        return undefined;
    };

    const getParams = (saleState: number) => {
        const params = [mintAmount];

        if (saleState === 1) {
            params.push(whitelistTree.getHexProof(keccak256(account)));
        }

        return params;
    };

    useEffect(() => {
        // Run only once on page load
        if (!!contract && !mintDetails) {
            getMintDetails();
        }

        if (
            account &&
            mintDetails?.saleState === 1 &&
            !mintDetails?.tokenAmount
        ) {
            getTokensInWallet(account);
        }

        // Disable mint-button on wallet disconnecting
        if (!mintDisabled && !account) {
            setMintDisabled(true);
        }
    }, [
        contract,
        mintDetails,
        account,
        mintDisabled,
        getMintDetails,
        getTokensInWallet,
    ]);

    return (
        <div id={slug.path} className={mintCard}>
            {inProgress && <LinearProgress />}
            <div>
                <Typography variant="title" component="h2">
                    {title}
                </Typography>
                <Typography component="div">
                    {documentToReactComponents(JSON.parse(description.raw))}
                </Typography>
                <div className={mintInfo}>
                    <Typography variant="marker" component="p">
                        Price is{" "}
                        <span>{mintDetails?.tokenPrice ?? "????"} ETH</span> per
                        token
                    </Typography>
                    <Typography variant="marker" component="p">
                        <span>{mintDetails?.totalSupply ?? "?"}</span> of{" "}
                        <span>3500</span> Beefy Blokes minted
                    </Typography>
                </div>
            </div>
            <div className={mintSection}>
                <img
                    src={mintGif.file.url}
                    alt="GIF showing some Beefy Blokes"
                    width={mintGif.file.details.image.width}
                    height={mintGif.file.details.image.height}
                />
                <ButtonGroup variant="counter">
                    <Button
                        variant="mint"
                        className="counter-btn"
                        disabled={!mintDetails || mintAmount === 1}
                        onClick={() =>
                            setMintAmount(prevAmount => --prevAmount)
                        }
                    >
                        -
                    </Button>
                    <Typography variant="marker" component="p">
                        {mintAmount}
                        {!!mintDetails && (
                            <span>
                                (
                                {(mintAmount * mintDetails.tokenPrice).toFixed(
                                    3,
                                )}{" "}
                                ETH)
                            </span>
                        )}
                    </Typography>
                    <Button
                        variant="mint"
                        className="counter-btn"
                        disabled={
                            !mintDetails ||
                            mintAmount >=
                                getMaxMintAmount(
                                    mintDetails?.saleState,
                                    mintDetails?.maxAmount,
                                    mintDetails?.tokenAmount,
                                )
                        }
                        onClick={() =>
                            setMintAmount(prevAmount => ++prevAmount)
                        }
                    >
                        +
                    </Button>
                </ButtonGroup>
                <Button
                    variant="mint"
                    disabled={
                        !mintDetails ||
                        mintDisabled ||
                        (mintDetails.saleState === 1 &&
                            getMaxMintAmount(
                                mintDetails.saleState,
                                mintDetails.maxAmount,
                                mintDetails.tokenAmount,
                            ) <= 0)
                    }
                    onClick={() =>
                        execContract({
                            setTxInProgress,
                            method: getMethod(mintDetails.saleState),
                            args: getParams(mintDetails.saleState),
                            payableAmount: parseEther(
                                String(mintAmount * mintDetails.tokenPrice),
                            ),
                            refetchData: async () => {
                                const totalSupply = await formatUnits(
                                    await contract.totalSupply(),
                                    0,
                                );
                                setMintDetails(prevDetails => ({
                                    ...prevDetails,
                                    totalSupply,
                                }));
                            },
                        })
                    }
                >
                    {getMintbtnLabel()}
                </Button>
            </div>
        </div>
    );
};

export default Mint;

export const query = graphql`
    fragment MintData on ContentfulSectionMint {
        id
        slug {
            path
        }
        title
        description {
            raw
        }
        mintGif {
            file {
                url
                details {
                    image {
                        width
                        height
                    }
                }
            }
        }
    }
`;
