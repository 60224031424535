import { utils } from "ethers";

export default (args: unknown[]) =>
    args.map(arg => {
        if (
            typeof arg === "number" ||
            (typeof arg === "string" && !arg.startsWith("0x"))
        ) {
            return utils.parseUnits(String(arg), 0);
        }

        return arg;
    });
