import { getArguments, getOptions } from "@utils";
import { serializeError } from "eth-rpc-errors";
import { Contract, providers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useSetAlert } from "./recoil";
import useWallet from "./use-wallet";

interface ContractInput {
    setTxInProgress: (value: boolean) => void;
    method: string;
    args?: unknown[];
    payableAmount?: unknown;
    refetchData?: () => void;
}

export default (address, abi) => {
    /* variables */
    const provider = useMemo(
        () =>
            new providers.AlchemyProvider(
                process.env.GATSBY_ETH_MAINNET === "true" ? null : "goerli",
                process.env.GATSBY_ALCHEMY_PROVIDER_ID,
            ),
        [],
    );

    /* state */
    const [contract, setContract] = useState(undefined);

    /* hooks */
    const setAlert = useSetAlert();
    const { signer } = useWallet();

    /* helpers */
    const getContract = useCallback(() => {
        const contract = new Contract(address, abi, signer ?? provider);
        setContract(contract);
    }, [abi, address, signer, provider]);

    const execContract = async ({
        setTxInProgress,
        method,
        args,
        payableAmount,
        refetchData,
    }: ContractInput) => {
        setTxInProgress(true);
        setAlert({
            severity: "success",
            message: "Transaction in progress",
        });

        const estimateGas = await contract.estimateGas[method](
            ...getArguments(args),
            getOptions(payableAmount),
        ).then(
            transaction => transaction,
            error => {
                const serError = serializeError(error);
                const message =
                    serError?.data?.originalError?.error?.message ??
                    serError?.message;
                setAlert({ severity: "warning", message });
            },
        );

        if (!estimateGas) {
            return setTxInProgress(false);
        }

        await contract[method](
            ...getArguments(args),
            getOptions(payableAmount, estimateGas),
        )
            .then(
                transaction =>
                    transaction.wait().then(
                        () =>
                            setAlert({
                                severity: "success",
                                message: "Transaction was successful",
                            }),
                        () =>
                            setAlert({
                                severity: "error",
                                message:
                                    "Something went wrong during transaction",
                            }),
                    ),
                () =>
                    setAlert({
                        severity: "warning",
                        message: "Transaction was canceled by the user",
                    }),
            )
            .then(() => {
                setTxInProgress(false);
                !!refetchData && refetchData();
            });
    };

    useEffect(() => {
        !!address && getContract();
    }, [signer, getContract, address]);

    return {
        contract,
        execContract,
    };
};
