import {
    atom,
    useRecoilValue,
    useResetRecoilState,
    useSetRecoilState,
} from "recoil";

const state = atom({
    key: "snackbarState",
    default: {
        message: "",
        severity: "info",
    },
});

// Get the Snackbar alert
export const useAlert = () => useRecoilValue(state);

// Set the Snackbar alert
export const useSetAlert = () => useSetRecoilState(state);

// Reset Snackbar alert
export const useClearAlert = () => useResetRecoilState(state);
