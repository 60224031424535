import { BannerImage } from "@components";
import { graphql } from "gatsby";
import React from "react";

const Header = ({ data: { slug, title, banner } }: unknown): Node => (
    <BannerImage
        id={slug.path}
        src={banner.file.url}
        alt={title}
        width={banner.file.details.image.width}
        height={banner.file.details.image.height}
    />
);

export default Header;

export const query = graphql`
    fragment HeaderData on ContentfulSectionHeader {
        id
        slug {
            path
        }
        title
        banner {
            file {
                url
                details {
                    image {
                        width
                        height
                    }
                }
            }
        }
    }
`;
