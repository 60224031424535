import { createTheme } from "@mui/material/styles";

// Fonts
const bebas = "'Bebas Neue', Helvetica, monospace";
const econom = "Economica, Helvetica, monospace";
const marker = "'Permanent Marker', Helvetica, monospace";

export default createTheme({
    palette: {
        primary: {
            main: "#86FAB4",
        },
        secondary: {
            main: "#28303D",
        },
        text: {
            main: "#191919",
        },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontFamily: econom,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontFamily: econom,
                    fontSize: "1.125em",
                    color: "white",

                    "@media (min-width: 48em)": {
                        fontSize: "1.25em",
                    },
                },
                title: {
                    fontFamily: bebas,
                    fontSize: "2em",
                    margin: "1.5em 0 .5em",
                    // color: "#fcff83",
                    color: "#54bce7",
                    textAlign: "center",
                    textShadow:
                        "0 0 0.5em rgb(84 188 231 / 25%), 0 0 1em rgb(84 188 231 / 25%)",
                    "@media (min-width: 768px)": {
                        fontSize: "3em",
                    },
                },
                bebas: {
                    fontFamily: bebas,
                    fontSize: "1.25em",
                },
                faqTitle: {
                    fontFamily: bebas,
                    fontSize: "1.25em",
                    padding: ".5em",
                    backgroundColor: "#1c1c1c",
                    transition: "color 250ms ease-in-out",
                },
                marker: {
                    fontFamily: marker,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#000",
                    color: "white",
                    boxShadow: "none",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "64px",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#000",
                    width: "90%",
                    alignItems: "center",
                    justifyContent: "center",

                    "@media (min-width: 640px)": {
                        width: "60%",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#111",
                    color: "white",
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                counter: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: bebas,
                    fontWeight: 800,
                    fontSize: "1.2em",
                    lineHeight: "1.5",
                    textTransform: "uppercase",
                    borderRadius: 0,
                    transition: "background-color .25s ease-in-out",
                    backgroundColor: "#1c1c1c",
                    color: "white",

                    "&:hover": {
                        backgroundColor: "#4091b3",
                    },
                },
                mint: {
                    backgroundColor: "#54bce7",
                    boxShadow:
                        "0 0 0.5em rgb(84 188 231 / 25%), 0 0 1em rgb(84 188 231 / 25%)",
                    color: "#1c1c1c",

                    "&.counter-btn": {
                        minWidth: "2em",
                        width: "2em",
                        height: "2em",
                    },

                    "&:hover": {
                        backgroundColor: "#4091b3",
                    },

                    "&.Mui-disabled": {
                        backgroundColor: "#c3edff",
                        boxShadow: "none",
                    },
                },
                image: {
                    padding: ".75em 1.25em",

                    "> img": {
                        width: "2em",
                        height: "auto",
                        marginRight: ".5em",
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    margin: "auto",
                    borderBottom: ".1em solid white",
                    boxShadow: "none",
                    background: "none",
                    borderRadius: 0,

                    "@media (min-width: 48em)": {
                        width: "90%",
                    },

                    "@media (min-width: 64em)": {
                        width: "70%",
                    },

                    "&:first-of-type": {
                        borderTop: ".1em solid white",
                        borderRadius: 0,
                    },

                    "&:last-of-type": {
                        borderRadius: 0,
                    },

                    "&.Mui-expanded": {
                        margin: "auto",
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    "&:hover .MuiAccordionSummary-expandIconWrapper": {
                        color: "#54BCE7",
                    },
                },
                content: {
                    "&:hover > .MuiTypography-faqTitle": {
                        color: "#54BCE7",
                    },
                },
                expandIconWrapper: {
                    padding: ".5em",
                    fontSize: "2em",
                    fontWeight: 900,
                    color: "white",
                    transition: "color 250ms ease-in-out",
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    position: "fixed",
                    top: "0",
                    left: "0",
                    zIndex: "10000",
                    height: "2px",
                    backgroundColor: "transparent",
                },
                bar: {
                    backgroundColor: "#54BCE7 !important",
                },
            },
        },
    },
});
