import { graphql } from "gatsby";
import React from "react";

const Section = ({ data }: unknown): Node => {
    let CustomComponent;

    try {
        /* eslint-disable-next-line */
        CustomComponent = require(`../${data.internal.type.replace(
            "ContentfulSection",
            "",
        )}`).default;
    } catch (error) {
        /* eslint-disable-next-line */
        return null;
    }

    return <CustomComponent data={data} />;
};

export default Section;

export const query = graphql`
    fragment SectionData on ContentfulPage {
        sections {
            ... on ContentfulSectionHeader {
                ...HeaderData
                internal {
                    type
                }
            }
            ... on ContentfulSectionAbout {
                ...AboutData
                internal {
                    type
                }
            }
            ... on ContentfulSectionMint {
                ...MintData
                internal {
                    type
                }
            }
            ... on ContentfulSectionFaq {
                ...FaqsData
                internal {
                    type
                }
            }
            ... on ContentfulSectionRoadmap {
                ...RoadmapData
                internal {
                    type
                }
            }
            ... on ContentfulSectionTeam {
                ...TeamData
                internal {
                    type
                }
            }
        }
    }
`;
