import { useAlert, useClearAlert } from "@hooks/recoil";
import { Alert, Snackbar as _Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

const Snackbar = (): Node => {
    const { message, severity } = useAlert();
    const clearAlert = useClearAlert();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        !!message && setOpen(true);
    }, [message]);

    const handleClose = () => {
        setOpen(false);
        clearAlert();
    };

    return (
        <_Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert severity={severity} onClose={handleClose}>
                {message}
            </Alert>
        </_Snackbar>
    );
};

export default Snackbar;
