import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion as _Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import React from "react";

interface Props {
    question: string;
    answer: string;
}

const Accordion = ({ question, answer }: Props): Node => (
    <_Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="faqTitle">{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">
                {documentToReactComponents(JSON.parse(answer))}
            </Typography>
        </AccordionDetails>
    </_Accordion>
);

export default Accordion;
