import { WalletConnector } from "@components";
import { useWindow } from "@hooks";
import { discord, opensea, twitter } from "@images/icons";
import logo from "@images/logo.png";
import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Drawer, SvgIcon, Toolbar, Typography } from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { Fragment, ReactElement, useState } from "react";

import { header, navigation, socialIcons, socials } from "./style.module.scss";

interface NavLink {
    id: string;
    label: string;
    changePage: boolean;
    link: { path: string };
}

interface ExternalLink {
    id: string;
    label: string;
    link: string;
}

interface NavLinks {
    navLinks: NavLink[];
    externalLinks: ExternalLink[];
}

interface Props {
    signer: unknown;
    handleConnect: (type?: string, onClose?: () => void) => void;
    twitterLink: string;
    discordLink: string;
    openseaLink: string;
}

interface ExtendedProps extends NavLinks {
    signer: unknown;
    handleConnect: (type?: string, onClose?: () => void) => void;
    twitterLink: string;
    discordLink: string;
    openseaLink: string;
}

const NavLinks = ({ navLinks, externalLinks }: NavLinks): ReactElement => (
    <div className={navigation}>
        {navLinks.map(({ id, label, changePage, link: { path } }) => (
            <AnchorLink
                key={id}
                to={changePage ? `/${path}` : `/#${path}`}
                title={label}
                stripHash
            >
                <Typography variant="bebas" component="span">
                    {label}
                </Typography>
            </AnchorLink>
        ))}
        {externalLinks.map(({ id, label, link }) => (
            <a key={id} href={link} target="_blank" rel="noreferrer noopener">
                <Typography variant="bebas" component="span">
                    {label}
                </Typography>
            </a>
        ))}
    </div>
);

const SocialLinks = ({
    signer,
    handleConnect,
    twitterLink,
    discordLink,
    openseaLink,
}: Props): ReactElement => (
    <div className={socials}>
        <div className={socialIcons}>
            <a href={twitterLink} target="_blank" rel="noreferrer noopener">
                <SvgIcon component={twitter} viewBox="0 0 248 204" />
            </a>
            <a href={discordLink} target="_blank" rel="noreferrer noopener">
                <SvgIcon component={discord} viewBox="0 0 71 55" />
            </a>
            <a href={openseaLink} target="_blank" rel="noreferrer noopener">
                <SvgIcon component={opensea} viewBox="0 0 90 90" />
            </a>
        </div>
        <WalletConnector signer={signer} handleConnect={handleConnect} />
    </div>
);

const Navigation = ({ children }: ReactElement): ReactElement => {
    // state
    const [open, setOpen] = useState<boolean>(false);

    // hooks
    const { width } = useWindow();

    if (typeof width === "undefined") return null;

    if (width < 880) {
        return (
            <Fragment>
                <MenuIcon onClick={() => setOpen(true)} />
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    {children}
                </Drawer>
            </Fragment>
        );
    }

    return children;
};

const NavBar = ({
    signer,
    handleConnect,
    navLinks,
    externalLinks,
    twitterLink,
    discordLink,
    openseaLink,
}: ExtendedProps): ReactElement => (
    <AppBar position="sticky">
        <Toolbar disableGutters className={header}>
            <AnchorLink to={"/#home"} title={"home"} stripHash>
                <img src={logo} width={1300} height={1024} />
            </AnchorLink>
            <Navigation>
                <NavLinks navLinks={navLinks} externalLinks={externalLinks} />
                <SocialLinks
                    signer={signer}
                    handleConnect={handleConnect}
                    twitterLink={twitterLink}
                    discordLink={discordLink}
                    openseaLink={openseaLink}
                />
            </Navigation>
        </Toolbar>
    </AppBar>
);

export default NavBar;
