import "swiper/scss";
import "swiper/scss/effect-coverflow";
import "swiper/scss/navigation";

import { TeamMember as _TeamMember } from "@components";
import { Typography } from "@mui/material";
import { TeamMember } from "@types";
import React from "react";
import { EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface Props {
    id: string;
    title: string;
    teamMembers: TeamMember[];
}

const TeamGrid = ({ id, title, teamMembers }: Props): Node => (
    <div id={id}>
        <Typography variant="title" component="h2">
            {title}
        </Typography>
        <Swiper
            effect="coverflow"
            modules={[EffectCoverflow, Navigation]}
            coverflowEffect={{
                rotate: 30,
                stretch: 0,
                slideShadows: false,
            }}
            navigation
            centeredSlides
            slidesPerView={1}
            spaceBetween={50}
            breakpoints={{
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
            }}
        >
            {teamMembers.map(({ id, pfp, name, role, description }) => (
                <SwiperSlide key={id}>
                    <_TeamMember
                        pfp={{
                            src: pfp.file.url,
                            alt: pfp.title,
                            width: pfp.file.details.width,
                            height: pfp.file.details.height,
                        }}
                        name={name}
                        role={role}
                        description={description}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
);

export default TeamGrid;
