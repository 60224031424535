import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Paper, Typography } from "@mui/material";
import { CFImage } from "@types";
import React from "react";

import { member, memberImage, memberInfo } from "./style.module.scss";

interface Props {
    pfp: CFImage;
    name: string;
    role: string;
    description: string;
}

const TeamMember = ({ pfp, name, role, description }: Props): Node => (
    <Paper className={member}>
        <img
            src={pfp.src}
            alt={pfp.alt}
            width={pfp.width}
            height={pfp.height}
            className={memberImage}
        />
        <div className={memberInfo}>
            <Typography variant="bebas" component="h3">
                {name}
            </Typography>
            <Typography variant="marker" component="p">
                {role}
            </Typography>
            <Typography component="div">
                {documentToReactComponents(JSON.parse(description.raw))}
            </Typography>
        </div>
    </Paper>
);

export default TeamMember;
