import { TeamGrid } from "@components";
import { TeamMember } from "@types";
import { graphql } from "gatsby";
import React from "react";

interface Data {
    data: {
        title: string;
        teamMembers: TeamMember[];
    };
}

const Team = ({ data: { slug, title, teamMembers } }: Data): Node => (
    <TeamGrid id={slug.path} title={title} teamMembers={teamMembers} />
);

export default Team;

export const query = graphql`
    fragment TeamData on ContentfulSectionTeam {
        id
        slug {
            path
        }
        title
        teamMembers {
            id
            pfp {
                title
                file {
                    url
                    details {
                        image {
                            width
                            height
                        }
                    }
                }
            }
            name
            role
            description {
                raw
            }
        }
    }
`;
